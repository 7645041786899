import React from 'react';

const HamburgerButton = React.forwardRef(({ onClick, isOpen }, ref) => {
  return (
    <button ref={ref}
      className="absolute top-4 right-4 focus:outline-none z-10"
      onClick={onClick}
    >
      <span
        className={`block w-6 h-1 mb-1 bg-black transition-all duration-300 transform ${
          isOpen ? 'rotate-45 translate-y-1.5' : ''
        }`}
      ></span>
      <span
        className={`block w-6 h-1 mb-1 bg-black transition-all duration-300 ${
          isOpen ? 'opacity-0' : ''
        }`}
      ></span>
      <span
        className={`block w-6 h-1 bg-black transition-all duration-300 transform ${
          isOpen ? '-rotate-45 -translate-y-1.5' : ''
        }`}
      ></span>
    </button>
  );
});

export default HamburgerButton;

import React, { useState, useEffect } from 'react';
// import NoteEditor from './NoteEditor';
// import { saveAsTemplate } from './CurrentTaskHandlers';
import Timer from './Timer';

// const CurrentTaskView = ({ currentTask, setCurrentTask, auth0Id, setPomodoroCount, noteContent }) => {
  
const CurrentTaskView = ({ currentTask, setCurrentTask, auth0Id, setPomodoroCount }) => {

  // const [buttonText, setButtonText] = useState('Save as Template');
  // const [buttonClass, setButtonClass] = useState('text-sm text-blue-200 font-bold uppercase mt-4 hover:text-blue-500 transition-colors duration-200 hidden md:block');
  // const [saveSuccess, setSaveSuccess] = useState(false);

  // const handleSaveAsTemplate = async () => {
  //   if (currentTask && noteContent) {
  //     const newTemplate = await saveAsTemplate(noteContent, currentTask.name);
  //     if (newTemplate) {
  //       setSaveSuccess(true);
  //       setTimeout(() => setSaveSuccess(false), 2000);
  //     }
  //   }
  // };

  // const handleClick = async () => {
  //   try {
  //     await handleSaveAsTemplate();
  //     setButtonText('Template Saved!');
  //     setButtonClass('text-sm text-green-500 font-bold uppercase hover:text-green-700 transition-colors duration-200  hidden  md:block');
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // useEffect(() => {
  //   if (buttonText === 'Template Saved!') {
  //     const timeoutId = setTimeout(() => {
  //       setButtonText('Save as Template');
  //       setButtonClass('text-sm text-gray-300 font-bold uppercase hover:text-gray-700 transition-colors duration-200 ');
  //     }, 2000);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [buttonText]);

  console.log("      [CURRENTTASKVIEW.JS] LOADED")

  return (
    <div className="px-4 py-4 pb-0 text-left flex flex-col h-full relative bg-gray-100 leading-relaxed">
      <div className="text-center mb-1">
        {currentTask ?<h1 className="text-sm uppercase text-gray-500 text-center w-full">Current Task</h1>:""}
        <h2 className="text-3xl font-bold leading-normal">
          {currentTask ? (
            <span id="current-task">{currentTask.name}</span>
          ) : (
            <span id="current-task" className='text-3xl text-gray-300'>Select a task</span>
          )}
        </h2>
        {currentTask && (
          <div className="absolute top-0 right-0 mt-3 mr-16 hidden md:block">
            <Timer minutes={25} onTimerEnd={() => setPomodoroCount((count) => count + 1)} />
          </div>
        )}
      </div>
      {/* <button 
        onClick={handleClick} 
        className={buttonClass}
      >
        {buttonText}
      </button>   */}
      {/* <div className="w-full flex-1 overflow-y-auto  hidden md:block">
      {currentTask && noteContent !== null && noteContent !== '' && noteContent !== '[]' && (
        <NoteEditor
          key={currentTask._id} // use currentTask._id as key
          currentTask={currentTask}
          noteContent={noteContent} 
          setCurrentTask={setCurrentTask}
          auth0Id={auth0Id}
        />
      )}

      </div> */}
    </div>
  );
};

export default CurrentTaskView;

import React, { useState, useEffect } from 'react';
import { getAllTasks } from '../../db';
import Task from '../../components/Task';

const CompletedTasks = ({auth0Id}) => {
  const [completedTasks, setCompletedTasks] = useState([]);

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      const allTasks = await getAllTasks(auth0Id);
      const tasks = allTasks.filter((task) => task.completed);

      tasks.sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at));

      const tasksByDate = tasks.reduce((result, task) => {
        const completedDate = new Date(task.completed_at);
        completedDate.setHours(0, 0, 0, 0);
        const dateKey = completedDate.toISOString();

        if (!result[dateKey]) {
          result[dateKey] = [];
        }

        result[dateKey].push(task);
        return result;
      }, {});

      setCompletedTasks(tasksByDate);
    };

    fetchCompletedTasks();
  }, [completedTasks]);

  return (
    <div className="flex flex-col justify-start h-screen bg-gray-200 overflow-y-auto">
      <div className="w-full max-w-xl px-6 py-4 bg-white rounded-lg shadow-md mx-auto my-4">
        <h1 className="text-2xl font-bold mb-4">Completed Tasks</h1>
        {Object.entries(completedTasks).map(([date, tasks]) => (
          <div key={date} className="mb-6">
            <h2 className="text-xl font-semibold mb-2">{new Date(date).toLocaleDateString()}</h2>
            <ul>
              {tasks.map((task) => (
                <li key={task._id}>
                  <Task task={task} />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedTasks;

import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import IncompleteTaskList from './IncompleteTaskList';
import CompletedTaskList from './CompletedTaskList';

const TodoListContent = ({ tasks, waterCount, onDragEnd, onDelete, onToggle, onMakeCurrent, pomodoroCount, updateTaskName, isSystemMessageIncoming}) => {
  
  const incompleteTasks = tasks.filter((task) => !task.completed);
  const completedTasks = tasks.filter((task) => task.completed);

  return (
    <div className="px-4 py-4 pb-0 text-left">
        <h1 className="text-2xl font-bold mb-4 hidden md:block">To Do List</h1>
        <DragDropContext onDragEnd={onDragEnd}>
        <IncompleteTaskList
          tasks={incompleteTasks}
          onDelete={onDelete}
          onToggle={onToggle}
          onMakeCurrent={onMakeCurrent}
          updateTaskName={updateTaskName}
          isSystemMessageIncoming={isSystemMessageIncoming} // new prop
        />
        <h1 className="text-2xl font-bold mt-4 hidden md:block">Completed</h1>
        <p className="mt-2 mb-4">
          💧: {waterCount}
          &nbsp;&nbsp;&nbsp;&nbsp;
          ⏲️: {pomodoroCount} {/* // TODO: implement pomodoro count */}
        </p>
        <CompletedTaskList
          tasks={completedTasks}
          onDelete={onDelete}
          onToggle={onToggle}
          onMakeCurrent={onMakeCurrent}
          updateTaskName={updateTaskName}
          isSystemMessageIncoming={isSystemMessageIncoming} // new prop
        />  
        </DragDropContext>
    </div>
  );
};

export default TodoListContent;
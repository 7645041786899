import React, { useState, useEffect, useContext } from 'react';
import { updateTask, deleteTask } from '../db';
import { onDragEnd, onDelete, onToggle, onMakeCurrent, updateWaterCount } from './TodoList/TodoListHandlers';
import { TodoListSocket } from '../utilities/Socket';

// COMPONENTS
import TodoListView from './TodoList/TodoListView';

// CONTEXTS
import SocketContext from '../contexts/SocketContext';
import UserContext from '../contexts/UserContext';

const TodoList = ({setCurrentTask, pomodoroCount, currentTask, tasks, setTasks }) => {
  const [waterCount, setWaterCount] = useState(0);
  const [isSystemMessageIncoming, setIsSystemMessageIncoming] = useState(false);

  const socket = useContext(SocketContext);
  const { auth0Id, userId } = useContext(UserContext)

  const updateTaskName = async (taskId, newName, auth0Id) => {
    const updatedTasks = tasks.map((task) =>
      task._id === taskId ? { ...task, name: newName } : task
    );

    const updatedTask = updatedTasks.find((task) => task._id === taskId);
    await updateTask(updatedTask, auth0Id);

    setTasks(updatedTasks);
  };
  
  useEffect(() => {
    setWaterCount(updateWaterCount(tasks));
  }, [tasks]);

  TodoListSocket(userId, setTasks, () => setWaterCount(updateWaterCount(tasks)), setIsSystemMessageIncoming, socket);

  return (
    <TodoListView
      tasks={tasks}
      waterCount={waterCount}
      pomodoroCount={pomodoroCount}
      onDragEnd={(result) => onDragEnd(result, tasks, setTasks, updateTask, auth0Id)}
      onDelete={(taskId) => onDelete(taskId, tasks, setTasks, deleteTask, auth0Id)}
      onToggle={(taskId) => onToggle(taskId, tasks, setTasks, updateTask, auth0Id)}
      onMakeCurrent={(taskId) => onMakeCurrent(taskId, tasks, setTasks, updateTask, setCurrentTask, auth0Id)}
      updateTaskName={updateTaskName}
      isSystemMessageIncoming={isSystemMessageIncoming}
    />
  );
};

export default TodoList;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Task = ({ task, onDelete, onToggle, onMakeCurrent, updateTaskName, isSystemMessageIncoming }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(task.name);

  const taskClassName = `flex items-center mb-2 p-2 rounded-md shadow-sm ${
    task.completed ? 'bg-green-100' : 'bg-white'
  }${task.isCurrentTask ? ' border-l-4 border-blue-500' : ''}`;

  const handleUpdate = async () => {
    setIsEditing(false);
    if (newName !== task.name) {
      await updateTaskName(task._id, newName);
    }
  };

  return (
    <div className={taskClassName}>
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 mr-2"
        checked={task.completed}
        onChange={!isSystemMessageIncoming && onToggle ? () => onToggle(task._id) : null}
        disabled={isSystemMessageIncoming}
      />
      {isEditing ? (
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          onBlur={handleUpdate}
          autoFocus
          disabled={isSystemMessageIncoming}
        />
      ) : (
        <p
          className={`flex-grow ${
            task.completed ? 'line-through text-gray-500' : 'text-gray-800'
          }`}
          onDoubleClick={!isSystemMessageIncoming ? () => setIsEditing(true) : null}
        >
          {task.name}
        </p>
      )}

      {onMakeCurrent && !task.isCurrentTask && !task.completed && (
        <button
          className="text-blue-400 hover:text-blue-600 focus:outline-none mr-3"
          onClick={!isSystemMessageIncoming ? () => onMakeCurrent(task._id) : null}
          disabled={isSystemMessageIncoming}
        >
          <FontAwesomeIcon icon="star" />
        </button>
      )}
      {onDelete && (
        <button
          className="text-gray-200 hover:text-red-400 focus:outline-none"
          onClick={!isSystemMessageIncoming ? () => onDelete(task._id) : null}
          disabled={isSystemMessageIncoming}
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      )}
    </div>
  );
};

export default Task;

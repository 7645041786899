import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../../contexts/UserContext';
import { getUser, updateUser } from '../../db'; // assuming you have getUser and updateUser functions in db.js

const Routines = () => {
  const { auth0Id } = useContext(UserContext);
  const [routine, setRoutine] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (auth0Id) {
        const user = await getUser(auth0Id);
        setRoutine(user.routine);
      }
    };
  
    fetchUser();
  }, [auth0Id]);

  const handleSaveRoutine = async () => {
    try {
      const updatedUser = {
        auth0Id: auth0Id,
        routine: routine,
      };
      await updateUser(updatedUser);
      setMessage({ success: true, text: 'Routine updated successfully!' });
    } catch (error) {
      setMessage({ success: false, text: 'Something went wrong while updating the routine. Please try again.' });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
      <div className="w-full max-w-xl px-6 py-4 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Routines</h1>
        <p className="mb-4 text-gray-600">Routines are a list of tasks that you always want to add to your day. You can just tell Task Buddy that "It's a new day!" or "Let's start the day" to add to your todo list.</p>
        <textarea
          className="w-full h-64 p-3 mb-4 border rounded-lg focus:outline-none focus:shadow-outline"
          value={routine}
          onChange={(e) => setRoutine(e.target.value)}
        />
        <button
          className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none"
          onClick={handleSaveRoutine}
        >
          Save
        </button>
        {message && (
          <div className={`mt-4 text-center text-sm font-bold ${message.success ? 'text-green-600' : 'text-red-600'}`}>
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default Routines;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import io from 'socket.io-client';
import { getAllTasks } from './db';
import { getToday } from './utilities/Socket'; 

// PAGES
import CompletedTasks from './pages/completed';
import LoginPage from './pages/login';
import Home from './pages/home';
import Template from './pages/templates';
import Routines from './pages/routines';

// COMPONENTS
import SideNavbar from './components/SideNavbar';
import HamburgerButton from './components/HamburgerButton';

// CONTEXTS
import SocketContext from './contexts/SocketContext';  
import UserContext from './contexts/UserContext'; 
import AuthContext from './contexts/AuthContext';
import TaskContext from './contexts/TaskContext';

function App() {
  console.log("APP.JS LOADED")
  const { isLoading, isAuthenticated, user } = useAuth0();
  const [sideNavVisible, setSideNavVisible] = useState(false);
  const [auth0Id, setAuth0Id] = useState(null);
  const [userId, setUserId] = useState(null);
  const [socket, setSocket] = useState(null);

  const [currentTask, setCurrentTask] = useState(null);
  const [pomodoroCount, setPomodoroCount] = useState(0);
  const [tasks, setTasks] = useState([]);
  const indexToSidRef = useRef(null); 
  
  const retryInterval = useRef(1000);
  const sideNavRef = useRef(null);
  const sideNavButtonRef = useRef(null);

  const socketValue = useMemo(() => socket, [socket]);
  const value = useMemo(() => ({ auth0Id, userId, setUserId }), [auth0Id, userId]);

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const toggleSideNav = () => {
    console.log("toggleSideNav");
    setSideNavVisible(!sideNavVisible);
  };

  const fetchTasks = async () => {
    const allTasks = await getAllTasks(auth0Id);  
    const today = getToday();
    const filteredTasks = allTasks.filter(task => {
      if (!task.completed) return true;
      const completedDate = new Date(task.completed_at);
      completedDate.setHours(0, 0, 0, 0);
      return completedDate.getTime() === today.getTime();
    });
  
    const sortedTasks = filteredTasks.sort((a, b) => {
      return b.created_at - a.created_at; // sort by created_at (oldest first)
    });
  
    return sortedTasks;
  };

  useEffect(() => {
    (async () => {
      const tasks = await fetchTasks(auth0Id);
      setTasks(tasks);
      const current = tasks.find((task) => task.isCurrentTask);
      setCurrentTask(current);
    })();
  }, [auth0Id]); 

  useEffect(() => {
    if (user?.sub) {
      setAuth0Id(user.sub);
    }
  }, [user]);

  useEffect(() => {
    if(userId){
      const socketInstance = io(BASE_URL, {
        transports: ['websocket'],
      });

      socketInstance.on('connect', () => {
        console.log('Connected to server');
        socketInstance.emit('join-room', userId, (message) => {
          console.log(message, userId);
        });
      });

      socketInstance.on('disconnect', () => {
        console.log('Disconnected from server');
        handleReconnect();
      });

      setSocket(socketInstance);

      return () => socketInstance.disconnect();
    }
  }, [userId]); 

  const handleReconnect = () => {
    setTimeout(() => {
      console.log(`Attempting to reconnect. Retry interval: ${retryInterval.current / 1000} seconds`);
      try{
        socket.connect();
      } catch (error) {
        console.error('Error reconnecting:', error);
      }
      if (retryInterval.current < 128000) { // Cap the maximum retry interval to 128 seconds
        retryInterval.current *= 2; // Double the retry interval for next time if this attempt fails
      }
    }, retryInterval.current);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target) &&
          sideNavButtonRef.current && !sideNavButtonRef.current.contains(event.target)) {
        console.log('clicked outside!');
        setSideNavVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  if (isLoading) {
    return <div></div>;
  }

  return (
    <AuthContext.Provider value={value}>
      <TaskContext.Provider value={{ auth0Id, currentTask, setCurrentTask, setPomodoroCount, tasks, setTasks, indexToSidRef }}>
        <SocketContext.Provider value={socketValue}>
          <UserContext.Provider value={{ auth0Id, userId }}>
            <Router>
              <div className="App h-screen flex flex-col">
                <Routes>
                  <Route path="/login" element={<LoginPage setUserId={setUserId} />} />
                  <Route path="/completed" element={isAuthenticated ? <CompletedTasks auth0Id={auth0Id} /> : <LoginPage setUserId={setUserId} />} />
                  <Route
                    path="/"
                    element={
                      isAuthenticated && auth0Id && userId && user && socket ? <Home /> : <LoginPage setUserId={setUserId} />
                    }
                  />
                  <Route path="/templates" element={isAuthenticated ? <Template /> : <LoginPage setUserId={setUserId} />} />
                  <Route path="/routines" element={isAuthenticated ? <Routines /> : <LoginPage setUserId={setUserId} />} />
                </Routes>
                <HamburgerButton ref={sideNavButtonRef} onClick={toggleSideNav} isOpen={sideNavVisible} />
                {sideNavVisible && <SideNavbar setSideNavVisible={setSideNavVisible} ref={sideNavRef} loggedIn={isAuthenticated} />}
              </div>
            </Router>
          </UserContext.Provider>
        </SocketContext.Provider>
      </TaskContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
import React, { useState, useContext, useEffect, useRef } from 'react';
import { fetchNote } from './CurrentTask/CurrentTaskHandlers';
import CurrentTaskView from './CurrentTask/CurrentTaskView';
import { CurrentTaskSocket } from '../utilities/Socket';

// CONTEXTS
import SocketContext from '../contexts/SocketContext';
import UserContext from '../contexts/UserContext';
import TaskContext from '../contexts/TaskContext';  // import the TaskContext

const CurrentTask = () => {
  const socket = useContext(SocketContext);
  const { auth0Id } = useContext(UserContext);
  const { currentTask, setCurrentTask, setPomodoroCount } = useContext(TaskContext);  // get the states from TaskContext
  
  // const [noteContent, setNoteContent] = useState([]);

  // useEffect(() => {
  //   if (currentTask && currentTask.note) {
  //     fetchNote(currentTask.note)
  //       .then(note => setNoteContent(JSON.parse(note.content)))
  //       .catch(err => console.error(err));
  //   } else {
  //     setNoteContent([]);
  //   }
  // }, [currentTask]);

  CurrentTaskSocket(setCurrentTask, currentTask, socket);

  console.log("    [CURRENTTASK.JS] LOADED")

  return (
    <CurrentTaskView
      // noteContent={noteContent}
      setPomodoroCount={setPomodoroCount}
      // fetchNote={fetchNote}
      currentTask={currentTask}
      setCurrentTask={setCurrentTask}
      auth0Id={auth0Id}
    />
  );
};

export default CurrentTask;

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const TASK_URL = `${BASE_URL}/api/tasks`
const TEMPLATE_URL = `${BASE_URL}/api/templates`
const USER_URL = `${BASE_URL}/api/users`

export const withErrorHandling = (fn) => async (...args) => {
  try {
    return await fn(...args);
  } catch (error) {
    console.error(error);
    throw error; // or handle error as you prefer
  }
};

export const getAllTasks = withErrorHandling(async (auth0Id) => {  
  if(!auth0Id) return [];
  const response = await fetch(`${TASK_URL}?auth0Id=${auth0Id}`);  
  const data = await response.json();
  return data;
});


export const addTask = withErrorHandling(async (task, auth0Id) => {
  const response = await fetch(`${TASK_URL}?auth0Id=${auth0Id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(task),
  });
  const data = await response.json();
  return data;
});


export const updateTask = withErrorHandling(async (task, auth0Id) => {
  const response = await fetch(`${TASK_URL}/${task._id}?auth0Id=${auth0Id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(task),
  });
  const data = await response.json();
  return data;
});

export const deleteTask = withErrorHandling(async (id, auth0Id) => {
  await fetch(`${TASK_URL}/${id}?auth0Id=${auth0Id}`, {
    method: 'DELETE',
  });
});


export const getAllTemplates = withErrorHandling(async () => {
  const response = await fetch(TEMPLATE_URL);
  const data = await response.json();
  return data;
});

export const addTemplate = withErrorHandling(async (template) => {
  const response = await fetch(TEMPLATE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(template),
  });
  const data = await response.json();
  return data;
});

export const updateTemplate = withErrorHandling(async (template) => {
  const response = await fetch(`${TEMPLATE_URL}/${template._id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(template),
  });
  const data = await response.json();
  return data;
});

export const deleteTemplate = withErrorHandling(async (id) => {
  await fetch(`${TEMPLATE_URL}/${id}`, {
    method: 'DELETE',
  });
});

export const getUser = withErrorHandling(async (auth0Id) => {
  const response = await fetch(`${USER_URL}/${auth0Id}`);
  const data = await response.json();
  return data;
});

export const addUser = withErrorHandling(async (user) => {
  const response = await fetch(`${USER_URL}/auth0`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({user}),
  });
  const data = await response.json();
  return data;
});

export const updateUser = withErrorHandling(async (user) => {
  console.log("in update User")
  const response = await fetch(`${USER_URL}/${user.auth0Id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  console.log("response", response)
  const data = await response.json();
  return data;
});

export const deleteUser = withErrorHandling(async (auth0Id) => {
  await fetch(`${USER_URL}/${auth0Id}`, {
    method: 'DELETE',
  });
});

export const onDragEnd = async (result, tasks, setTasks, updateTask, auth0Id) => {
    const { source, destination } = result;
  
    // If the user drops the task outside a droppable area, do nothing
    if (!destination) {
      return;
    }

    const incompleteTasks = tasks.filter((task) => !task.completed);
    const completedTasks = tasks.filter((task) => task.completed);
  
    const sourceList = source.droppableId === 'incompleteTasks' ? incompleteTasks : completedTasks;
    const destinationList = destination.droppableId === 'incompleteTasks' ? incompleteTasks : completedTasks;
  
    const draggedTask = sourceList[source.index];
  
    // Remove the dragged task from its original position in the tasks array
    const newTasks = tasks.filter((task) => task._id !== draggedTask._id);
  
    // Find the destination index in the tasks array
    const destinationTask = destinationList[destination.index] || null;
    const destinationIndex = destinationTask ? tasks.findIndex((task) => task._id === destinationTask._id) : tasks.length;
  
    // Update the dragged task's `completed` status if moving between lists
    if (source.droppableId !== destination.droppableId) {
      draggedTask.completed = !draggedTask.completed;
    }
  
    // Insert the dragged task at the destination index
    newTasks.splice(destinationIndex, 0, draggedTask);
  
    // Update the tasks state
    setTasks(newTasks);
  
    // Update the dragged task in the database
    await updateTask(draggedTask, auth0Id);
  };
  
  export const onDelete = async (taskId, tasks, setTasks, deleteTask, auth0Id) => {
    await deleteTask(taskId, auth0Id);
    setTasks(tasks.filter((task) => task._id !== taskId));
  };
  
  export const onToggle = async (taskId, tasks, setTasks, updateTask, auth0Id) => {
    const updatedTasks = tasks.map((task) =>
        task._id === taskId ? { ...task, completed: !task.completed } : task
    );

    const updatedTask = updatedTasks.find((task) => task._id === taskId);
    await updateTask(updatedTask, auth0Id);

    setTasks(updatedTasks);
  };
  
  export const onMakeCurrent = async (taskId, tasks, setTasks, updateTask, setCurrentTask, auth0Id) => {
    // Find the new current task
    const newCurrentTask = tasks.find((task) => task._id === taskId);
  
    if (!newCurrentTask) return;
  
    newCurrentTask.isCurrentTask = true;
    newCurrentTask.completed = false; // Move the task back to To Do
  
    await updateTask(newCurrentTask, auth0Id);
  
    // Update the tasks state
    const sortedTasks = tasks
      .map(task => {
        if (task._id === taskId) {
          return newCurrentTask;
        }
  
        // If it's not the new current task and it was the current task, set it as not current
        if (task.isCurrentTask) {
          const updatedTask = { ...task, isCurrentTask: false };
          updateTask(updatedTask, auth0Id);
          return updatedTask;
        }
  
        return task;
      })
      .sort((a, b) => {return b.created_at - a.created_at}); // sort by created_at (newest first);});
  
    // Update the tasks state with sorted tasks
    setTasks(sortedTasks);
  
    // Update the current task in the parent component
    setCurrentTask(newCurrentTask);
  };
  
  
export const updateWaterCount = (tasks) => {
  return tasks.reduce((sum, task) => {
    if (!task.completed) return sum;

    const waterTag = task.tags.find(tag => tag.includes('water'));

    if (!waterTag) return sum;

    const value = waterTag.split(':')[1];
    return sum + (value ? parseInt(value.trim(), 10) : 1);
  }, 0);
};

  
import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import './ChatWindow/ChatWindow.css';
import { ChatWindowSocket } from '../utilities/Socket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

// CONTEXTS
import SocketContext from '../contexts/SocketContext';
import UserContext from '../contexts/UserContext';

const ChatWindow = ({ tasks, setCurrentTask, indexToSidRef }) => {
  const socket = useContext(SocketContext);
  const { auth0Id } = useContext(UserContext)
  
  const [inputValue, setInputValue] = useState(''); // Input value for the chat input
  const [currentDraft, setCurrentDraft] = useState(''); // Current draft message
  const [messages, setMessages] = useState([]); // Prior user messages for easy up arrow navigation
  const [isBotTyping, setIsBotTyping] = useState(false); // Adds a 'Thinking' state to the chat window
  const inputRef = useRef(); // Help with height adjustment of the input box while typing
  const chatWindowRef = useRef(); // Help with height adjustment of the chat window while typing
  const chatWindowBottomRef = useRef(); // Help with scrolling to the bottom of the chat window

  const [userMessageHistory, setUserMessageHistory] = useState([]); // Keep track of user message history for up arrow navigation
  const [userMessageIndex, setUserMessageIndex] = useState(-1); // Keep track of user message index for up arrow navigation

  const botMessageRef = useRef(''); 
  const [botTypingContent, setBotTypingContent] = useState(''); // Help with displaying bot typing content

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const adjustInputHeight = useCallback(() => {
    const prevHeight = inputRef.current.offsetHeight;
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;

    if (prevHeight !== inputRef.current.offsetHeight) {
      adjustChatWindowHeight();
    }
  });

  const adjustChatWindowHeight = useCallback(() => {
    const chatWindowWrapper = chatWindowRef.current.parentElement;
    const chatWindowWrapperStyle = window.getComputedStyle(chatWindowWrapper);
    const paddingTop = parseFloat(chatWindowWrapperStyle.getPropertyValue('padding-top').slice(0, -2));
    const paddingBottom = parseFloat(chatWindowWrapperStyle.getPropertyValue('padding-bottom').slice(0, -2));

    const availableHeight = chatWindowWrapper.offsetHeight - paddingTop - paddingBottom - inputRef.current.offsetHeight;
    chatWindowRef.current.style.height = `${availableHeight}px`;
  });

  useEffect(() => {
    if (window.innerWidth > 768) { // only run this hook for non-mobile devices
        if (chatWindowBottomRef.current) {
            chatWindowBottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        adjustChatWindowHeight();
    }
  }, [messages]);

  useEffect(() => {
    const handleResize = () => {
      adjustInputHeight();
      adjustChatWindowHeight();
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [adjustInputHeight, adjustChatWindowHeight]);
  
  ChatWindowSocket(botMessageRef, setBotTypingContent, setMessages, setIsBotTyping, tasks, setCurrentTask, indexToSidRef, socket);

  const addUserMessage = useCallback((message) => {
    setMessages((prevMessages) => [...prevMessages, { sender: 'User', content: message }]);
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    adjustInputHeight();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      // Save current input as draft before navigating history
      if (userMessageIndex === -1) {
        setCurrentDraft(inputValue);
      }
      if (userMessageIndex < userMessageHistory.length - 1) {
        setUserMessageIndex((prevIndex) => prevIndex + 1);
        setInputValue(userMessageHistory[userMessageIndex + 1]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (userMessageIndex > -1) {
        setUserMessageIndex((prevIndex) => prevIndex - 1);
        // Retrieve draft if at the end of history navigation
        setInputValue(userMessageIndex === 0 ? currentDraft : userMessageHistory[userMessageIndex - 1]);
      }
    }
  };

  const sendMessage = async () => {
    if (inputValue.trim() !== '') {
      addUserMessage(inputValue);
      setInputValue('');
      setIsBotTyping(true);

      try {
        console.log(JSON.stringify({ message: inputValue, auth0Id: auth0Id }))
        const response = await fetch(`${BASE_URL}/api/openai?auth0Id=${auth0Id}`, { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: inputValue }),
        });
    
        if (response.ok) {
          const data = await response.json();
          console.log("DATA:", data);
          // No need to add the bot's response here because it will be handled by the socket event
        } else {
          console.error('Failed to send message');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsBotTyping(false);
      }
    
      inputRef.current.style.height = 'auto';
      adjustChatWindowHeight();
      setUserMessageHistory((prevHistory) => [inputValue, ...prevHistory]);
      setUserMessageIndex(-1);
    };
  };

  return (
    <div className="p-4 pb-4 h-full bg-gray-100">
      <div
        ref={chatWindowRef}
        className="mb-4 border border-gray-300 bg-white p-2 overflow-y-auto text-left"
        style={{ minHeight: '80px' }}
      >
        {messages.map((message, index) => (
          <div key={index} className="mb-2">
            <span className="font-bold">{message.sender}:</span>
            <div className="message-content">{message.content}</div>
          </div>
        ))}
       {isBotTyping && !botTypingContent && (
          <div className="mb-2">
            <div className="message-content">Thinking...</div>
          </div>
        )}
        <div ref={chatWindowBottomRef}></div>
      </div>
      <div className="relative">
        <textarea
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          className=" w-full p-2 pl-3 pr-10 border border-gray-300 resize-none"
          placeholder="Type your message here..."
        />
        <FontAwesomeIcon
          icon={faPaperPlane}
          onClick={sendMessage}
          className="absolute right-1 top-1/2 transform -translate-y-1/2 text-blue-500 px-4 py-2 font-bold rounded cursor-pointer "
        />
      </div>
    </div>
  );
};

export default ChatWindow;
import React, { useContext, memo } from 'react';
import { HomeSocket } from '../../utilities/Socket'; 

// COMPONENTS
import TodoList from '../../components/TodoList';
import CurrentTask from '../../components/CurrentTask';
import ChatWindow from '../../components/ChatWindow';

// CONTEXTS
import SocketContext from '../../contexts/SocketContext';
import UserContext from '../../contexts/UserContext';
import TaskContext from '../../contexts/TaskContext';

const Home = memo(() => {
  const socket = useContext(SocketContext);
  const { currentTask, setCurrentTask, pomodoroCount, setPomodoroCount, tasks, setTasks, indexToSidRef } = useContext(TaskContext);
  const { auth0Id } = useContext(UserContext);

  if (!auth0Id || !socket) return null;

  console.log("  [HOME/index.js] LOADED", auth0Id, socket)
  
  HomeSocket(currentTask, setCurrentTask, socket);

  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    // Mobile JSX
    return (
      <div className="flex flex-col h-screen overflow-auto bg-gray-100">
        <div className="sticky top-0 z-50 bg-white mb-4"> 
          <CurrentTask />
        </div>
        <div className="min-h-screen/2 overflow-y-auto bg-white">
          <TodoList currentTask={currentTask} setCurrentTask={setCurrentTask} pomodoroCount={pomodoroCount} tasks={tasks} setTasks={setTasks} />
        </div>
        <div className="mi-h-screen/4">
          <ChatWindow auth0Id={auth0Id} tasks={tasks} setCurrentTask={setCurrentTask} indexToSidRef={indexToSidRef} />
        </div>
      </div>
    );
  } else {
    // Desktop JSX
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="w-1/4 h-full overflow-y-auto">
          <TodoList currentTask={currentTask} setCurrentTask={setCurrentTask} pomodoroCount={pomodoroCount} tasks={tasks} setTasks={setTasks} />
        </div>
        <div className="w-3/4 h-full flex flex-col  bg-gray-100">
          <div style={{flex: 1}} className="overflow-y-auto">
            <CurrentTask currentTask={currentTask} setCurrentTask={setCurrentTask} setPomodoroCount={setPomodoroCount} />
          </div>
          <div style={{flex: 3}} className="overflow-y-auto mb-10">
            <ChatWindow auth0Id={auth0Id} tasks={tasks} setCurrentTask={setCurrentTask} indexToSidRef={indexToSidRef} />
          </div>
        </div>
      </div>
    );
  }
})

export default Home;
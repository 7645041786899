import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Task from '../Task';

const CompletedTaskList = ({ tasks, onDelete, onToggle, onMakeCurrent, updateTaskName, isSystemMessageIncoming }) => {
  return (
    <Droppable droppableId="completedTasks">
      {(provided) => (
        <ul {...provided.droppableProps} ref={provided.innerRef}>
          {tasks.map((task, index) => (
            <Draggable key={task._id} draggableId={String(task._id)} index={index}>
              {(provided) => (
                <li
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Task
                    task={task}
                    onDelete={onDelete}
                    onToggle={onToggle}
                    onMakeCurrent={onMakeCurrent}
                    updateTaskName={updateTaskName}
                    isSystemMessageIncoming={isSystemMessageIncoming} // new prop
                  />
                </li>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default CompletedTaskList;

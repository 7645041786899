import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faHome, faCheck, faCog, faSignInAlt, faSignOutAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';

const SideNavbar = React.forwardRef(({setSideNavVisible}, ref) => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <div ref={ref} className="flex flex-col justify-between absolute top-0 right-0 h-screen w-64 bg-gray-100 p-4">
      <div>
        <h2 className="text-xl font-bold mb-4">Menu</h2>
        <div>
          <Link to="/" onClick={() => setSideNavVisible(false)} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 mb-4 rounded w-full flex items-center">
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            Tasker
          </Link>
          {isAuthenticated && (
            <>
              <Link to="/completed" onClick={() => setSideNavVisible(false)} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 mb-4  rounded w-full flex items-center">
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Completed Tasks
              </Link>
              <Link to="/templates" onClick={() => setSideNavVisible(false)} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 mb-4  rounded w-full flex items-center">
                <FontAwesomeIcon icon={faCopy} className="mr-2" />
                Templates
              </Link>
              <Link to="/routines" onClick={() => setSideNavVisible(false)} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 mb-4  rounded w-full flex items-center">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Routines
              </Link>
            </>
            
            
          )}
        </div>
      </div>
      <div>
        {isAuthenticated ? (
          <button onClick={() => logout({ returnTo: window.location.origin })} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 mb-4 rounded w-full flex items-center">
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            Logout
          </button>
        ) : (
          <Link to="/login" onClick={() => setSideNavVisible(false)} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 rounded w-full flex items-center">
            <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
            Login
          </Link>
        )}
        <Link to="/settings" onClick={() => setSideNavVisible(false)} className="block bg-transparent hover:bg-gray-200 text-gray-800 hover:text-gray-900 px-4 py-2 mb-4 rounded w-full flex items-center">
          <FontAwesomeIcon icon={faCog} className="mr-2" />
          Settings
        </Link>
      </div>
    </div>
  );
});

export default SideNavbar;

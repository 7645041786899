import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import grayNoiseFile from './gray_noise.mp3'; // Import the gray noise sound file
import beepSoundFile from './beep.ogg'; // Import the beep sound file


const beepSound = new Audio(beepSoundFile); // Create an Audio instance for the beep sound

// Set the gray noise sound to loop
// const grayNoiseSound = new Audio(grayNoiseFile);
// grayNoiseSound.preload = 'auto';
// grayNoiseSound.loop = true;


const Timer = ({ minutes, onTimerEnd }) => {
  const [time, setTime] = useState(minutes * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);
  const [pressTimer, setPressTimer] = useState(null);
  const [longPressActive, setLongPressActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false); // New state variable for mute control

  useEffect(() => {
    // This will run only once when the component mounts
    // grayNoiseSound.play().catch(error => console.error('Error playing the sound:', error));
    
    return () => {
      // This will run when the component unmounts
      // grayNoiseSound.pause();
      // grayNoiseSound.currentTime = 0;
    };
    
  }, []);

  useEffect(() => {
    setTime(minutes * 60);
    setTimerEnded(false);
  }, [minutes]);

  useEffect(() => {
    if (isPaused || timerEnded) {
      // grayNoiseSound.pause();
    } else {
      // grayNoiseSound.play().catch(error => console.error('Error playing the sound:', error));
    }

    const timer = setTimeout(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        setTimerEnded(true);
        onTimerEnd();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [time, isPaused, timerEnded, onTimerEnd]);

  useEffect(() => {
    if (timerEnded) {
      // beepSound.play();
      // grayNoiseSound.pause(); // Stop the gray noise when the timer ends
      // grayNoiseSound.currentTime = 0; // Reset the audio position to the start
    }
  }, [timerEnded]);
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const toggleMute = (e) => {
    e.stopPropagation(); // This will prevent the click from affecting parent elements
    if (isMuted) {
      // grayNoiseSound.play().catch(error => console.error('Error playing the sound:', error));
    } else {
      // grayNoiseSound.pause();
    }
    setIsMuted(!isMuted);
  };
  

  const togglePause = () => {
    if (!longPressActive) {
      setIsPaused(!isPaused);
    }
  };

  const restartTimer = () => {
    setTime(minutes * 60);
    setIsPaused(false);
    setTimerEnded(false);
  };

  const handlePressStart = () => {
    setLongPressActive(false);
    setPressTimer(
      setTimeout(() => {
        setLongPressActive(true);
        restartTimer();
      }, 1000)
    );
  };

  const handlePressEnd = () => {
    clearTimeout(pressTimer);
    setLongPressActive(false);
  };

  const handleReset = (e) => {
    e.stopPropagation();
    restartTimer();
  }

  return (
    <div
      className={`timer ml-4 rounded-full text-white text-base font-bold p-4 w-32 h-8 flex items-center relative ${
        isPaused ? 'bg-gray-400' : 'bg-blue-400'
      } ${longPressActive ? 'scale-110' : ''}`}
      onClick={togglePause}
      onMouseDown={handlePressStart}
      onMouseUp={handlePressEnd}
      onMouseLeave={handlePressEnd}
      onTouchStart={handlePressStart}
      onTouchEnd={handlePressEnd}
      onTouchCancel={handlePressEnd}
    >
      {formatTime(time)}
      <button onClick={handleReset} className="absolute right-2 text-sm">
        <FontAwesomeIcon icon={faRedo} />
      </button>
      {/* Mute button */}
      <button onClick={toggleMute} className="absolute right-8 text-sm">
        <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
      </button>
    </div>
  );
};

export default Timer;
import React, { useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { addUser } from '../../db';
import AuthContext from '../../contexts/AuthContext';

function LoginPage() {
  const { loginWithRedirect, isAuthenticated, user, loginWithPopup } = useAuth0();
  const { setUserId } = useContext(AuthContext);
  const logoUrl = process.env.PUBLIC_URL + '/logo_lg.png'; // Correct way to refer to the logo image

  useEffect(() => {
    if (isAuthenticated && user) {
      addUser({
        auth0Id: user.sub,
        email: user.email,
        name: user.name
      }).then(response => {
        try {
            setUserId(response._id)
          } catch(error) {
            console.log('Error when calling setUserId:', error);
          }
      }).catch(error => {
        // Handle errors here
      });
    }
  }, [isAuthenticated, user]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="bg-[#fcedca] p-8 rounded shadow-xl w-96"> {/* Specified RGB background color */}
        <img src={logoUrl} alt="Tasker Logo" className="mx-auto mb-8 w-1/2" /> {/* Display the logo image */}
        <h2 className="text-2xl font-bold mb-8 text-gray-800">Task Buddy</h2>
        <button onClick={() => loginWithRedirect()} className="block w-full p-2 bg-[#415d62] text-white rounded mb-4">Log in</button>
        <button onClick={() => loginWithPopup()} className="block w-full p-2 bg-[#3CB371] disabled text-white rounded">Sign Up</button>
      </div>
    </div>
  );
}

export default LoginPage;

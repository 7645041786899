import React, { useEffect, useState } from 'react';
import { getAllTemplates, deleteTemplate } from '../../db';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

const Templates = () => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const allTemplates = await getAllTemplates();
      setTemplates(allTemplates);
    };

    fetchTemplates();
  }, []);

  const handleDeleteTemplate = async (id) => {
    await deleteTemplate(id);
    const updatedTemplates = templates.filter((template) => template._id !== id);
    setTemplates(updatedTemplates);
  };

  return (
    <div className="flex flex-col justify-start h-screen bg-gray-200 overflow-y-auto">
      <div className="w-full max-w-xl px-6 py-4 bg-white rounded-lg shadow-md mx-auto my-4">
        <h1 className="text-2xl font-bold mb-4">Templates</h1>
        <p className="mb-4 text-gray-600">Templates are a great way to save time writing notes for tasks that you do repeatedly, like journaling or an exercise routine.</p>
        {templates.map((template) => (
          <div key={template._id} className="flex items-center justify-between mb-4">
            <div className="w-full">
              <h2 className="text-lg font-semibold text-left">{template.name}</h2>
            </div>
            <div className="flex items-center justify-end">
              <button className="text-gray-200 hover:text-blue-400 focus:outline-none mr-3">
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              <button
                className="text-gray-200 hover:text-red-400 focus:outline-none"
                onClick={() => handleDeleteTemplate(template._id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
        {/* Placeholder for task editor component */}
      </div>
    </div>
  );
};

export default Templates;

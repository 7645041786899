import { useEffect } from 'react';

const getToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

const HomeSocket = (currentTask, setCurrentTask, socket) => {
  useEffect(() => {
    console.log("In the home socket function")
    if (socket) {
      const handleTaskUpdated = (updatedTask) => {
        if (updatedTask.isCurrentTask) {
          setCurrentTask(updatedTask);
        } else if (currentTask && currentTask._id === updatedTask._id) {
          setCurrentTask(null);
        }
      };
  
      const handleTaskCreated = (createdTask) => {
        if (createdTask.isCurrentTask) {
          setCurrentTask(createdTask);
        }
      };
  
      const handleTaskDeleted = (deletedTaskId) => {
        if (currentTask && currentTask._id === deletedTaskId) {
          setCurrentTask(null);
        }
      };
  
      socket.on('task-updated', handleTaskUpdated);
      socket.on('task-created', handleTaskCreated);
      socket.on('task-deleted', handleTaskDeleted);
  
      return () => {
        socket.off('task-updated', handleTaskUpdated);
        socket.off('task-created', handleTaskCreated);
        socket.off('task-deleted', handleTaskDeleted);
      };
    }
  }, [socket]);
}

// Specific socket functionality for TodoList
const TodoListSocket = (userId, setTasks, updateWaterCount, setIsSystemMessageIncoming, socket) => {
  useEffect(() => {
    if(socket){

      socket.on('message-received', (message) => {
        // Update the state to indicate that a system message is incoming
        setIsSystemMessageIncoming(true);
        
        if (message.complete) {
          setIsSystemMessageIncoming(false);
        }
      });

      socket.on('task-created', (newTask) => {
        setTasks((prevTasks) => {
          const updatedTasks = [...prevTasks, newTask];
          updateWaterCount(updatedTasks);
          return updatedTasks;
        });
      });
  
      socket.on('task-updated', (updatedTask) => {
        setTasks((prevTasks) => {
          const today = getToday();
          const completedDate = new Date(updatedTask.completed_at);
          completedDate.setUTCHours(0, 0, 0, 0);
  
          let updatedTasks;
          if (!updatedTask.completed || completedDate.getTime() === today.getTime()) {
            updatedTasks = prevTasks.map((task) => (task._id === updatedTask._id ? updatedTask : task));
          } else {
            updatedTasks = prevTasks.filter((task) => task._id !== updatedTask._id);
          }
          updateWaterCount(updatedTasks);
          return updatedTasks;
        });
      });
  
      socket.on('task-deleted', (taskId) => {
        setTasks((prevTasks) => {
          const updatedTasks = prevTasks.filter((task) => task._id !== taskId);
          updateWaterCount(updatedTasks);
          return updatedTasks;
        }); 
      });
  
      return () => {
        socket.disconnect();
      };
    }

  }, [socket]);
};

// Specific socket functionality for ChatWindow
const ChatWindowSocket = (botMessageRef, setBotTypingContent, setMessages, setIsBotTyping, tasks, setCurrentTask, indexToSidRef, socket) => {
  useEffect(() => {
    if (socket) {
      socket.on('message-received', async (message) => {
        // Accumulate content
        botMessageRef.current += message.content;

        // Try to parse the accumulated content
        try {
          let startIndex = 0;
          while (startIndex < botMessageRef.current.length) {
            const endIndex = botMessageRef.current.indexOf('}', startIndex);
            if (endIndex === -1) break; // No complete object found

            // Try to parse the current portion
            const potentialJson = botMessageRef.current.substring(0, endIndex + 1);
            try {
              const parsedMessage = JSON.parse(potentialJson);
              
              // Handle the parsed message
              const botResponse = parsedMessage.r;
              if (botResponse) {
                setMessages((prevMessages) => [...prevMessages, { sender: 'Chatbot', content: botResponse }]);
              }
              
              // Remove the parsed portion from the accumulated content
              botMessageRef.current = botMessageRef.current.substring(endIndex + 1);
              startIndex = 0; // Reset startIndex to parse any remaining content
            } catch (e) {
              // If parsing failed, it means we don't have a complete JSON object yet
              startIndex = endIndex + 1;
            }
          }
        } catch (error) {
          console.error('Error parsing bot message:', error);
        }

        // Update typing indicator with incremental content
        setBotTypingContent(botMessageRef.current);
        setIsBotTyping(true);

        // Handle indexToSid mapping
        if (message.indexToSid) {
          indexToSidRef.current = message.indexToSid;
        }

        // Handle task updates based on the content
        const regexContent = /c:\s*(\d+)/;
        const matchContent = botMessageRef.current.match(regexContent);
        if (matchContent) {
          const index = parseInt(matchContent[1], 10);
          const contentSid = indexToSidRef.current[index];
          if (contentSid) {
            const taskWithMatchingSid = tasks.find(task => task.sid === contentSid);
            if (taskWithMatchingSid) {
              setCurrentTask(taskWithMatchingSid);
            } else {
              console.log('New Task');
            }
          }
        }

        // Clear bot message once the message is complete
        if (message.complete) {
          botMessageRef.current = '';
          setBotTypingContent('');
          setIsBotTyping(false);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off('message-received');
      }
    };
  }, [socket, setBotTypingContent, setMessages, setIsBotTyping, tasks, setCurrentTask, indexToSidRef]);
};



// Specific socket functionality for CurrentTask
const CurrentTaskSocket = (setCurrentTask, currentTask, socket) => {
  useEffect(() => {
    if (socket) {

      socket.on('task-updated', (updatedTask) => {
        if (updatedTask.isCurrentTask) {
          setCurrentTask(updatedTask);
        } else if (currentTask && currentTask._id === updatedTask._id) {
          setCurrentTask(null);
        }
      });

      socket.on('task-created', (createdTask) => {
        if (createdTask.isCurrentTask) {
          setCurrentTask(createdTask);
        }
      });

      socket.on('task-deleted', (deletedTaskId) => {
        if (currentTask && currentTask._id === deletedTaskId) {
          setCurrentTask(null);
        }
      });

      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
  }, [socket]);  // Add socket to dependencies
};

export { TodoListSocket, ChatWindowSocket, CurrentTaskSocket, HomeSocket, getToday };